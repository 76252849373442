import React from "react";
import "./Kontakt.css";
import { BiMapPin } from "react-icons/bi";
import { AiOutlinePhone } from "react-icons/ai";
import { useTranslation } from "react-i18next";

export default function Kontakt() {
  const { t } = useTranslation();
  return (
    <div className="kontakt">
      <div className="kontakt_image1">
        <h1>{t("contact_heading")}</h1>
      </div>
      <div className="kontakt_container">
        <div className="kontakt_info">
          <div className="konakt_boxes">
            <h1 className="kontakt_boxes_info">Bestpol CD SP. Z O.O.</h1>
          </div>
          <div className="konakt_boxes">
            <div className="konakt_boxes_boxes">
              <BiMapPin size="2rem" />
            </div>
            <div className="konakt_boxes_boxes">
              <h4>{`${t("contact_street")} Diamentowa 1`}</h4>
              <h4>62-002 Suchy las</h4>
              <h4>{t("contact_country")}</h4>
              <h4>NIP 972-124-40-33</h4>
              <h4 className="kontakt_nip">KRS 0000461677</h4>
            </div>
          </div>
          <div className="konakt_boxes">
            <div className="konakt_boxes_boxes">
              <AiOutlinePhone size="2rem" />
            </div>
            <div className="konakt_boxes_boxes">
              <h4>{`${t("contact_phone")}+ (48) 502 363 508`}</h4>
              <h4>ar@bestpolcd.pl</h4>
              <h3>{t("contact_commercial_dept")}</h3>
              <h4>{`${t("contact_phone")}+ (48) 507 780 605`}</h4>
              <h4>rr@bestpolcd.pl</h4>
              <h3>{t("contact_magazine")}</h3>
              <h4>{`${t("contact_phone")}+ (48) 452 370 920`}</h4>
              <h4>07:00 - 15:00</h4>
            </div>
          </div>
        </div>
        <div className="kontakt_section_googlemaps">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.5184932799634!2d16.873715077032273!3d52.46974723994841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470443a7f4b7ee53%3A0x867f9a73dc3cf7e8!2sBestpol%20Cd%20Sp.%20Z%20O.o.!5e0!3m2!1spl!2spl!4v1691334811661!5m2!1spl!2spl"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="eager"
            referrerPolicy="no-referrer-when-downgrade"
            title="Google Maps"
          ></iframe>
        </div>
      </div>
    </div>
  );
}
